import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { PERMISSIONS } from '../guards/Permissions';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'rapports',
          children: [
            { element: <Navigate to="/dashboard/rapports/root" replace />, index: true },
            {
              path: 'root',
              element: (
                <RoleBasedGuard permission={PERMISSIONS.VIEW_REPORT_VENTE}>
                  <Rapport />
                </RoleBasedGuard>
              )
            },
            {
              path: 'journalier',
              element: (
                <RoleBasedGuard permission={PERMISSIONS.VIEW_REPORT_PARTIEL}>
                  <RapportJournalier />
                </RoleBasedGuard>
              )
            },
            {
              path: 'agent',
              element: (
                <RoleBasedGuard permission={PERMISSIONS.VIEW_REPORT_AGENT}>
                  <RapportAgent />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'transactions',
          children: [
            { element: <Navigate to="/dashboard/transactions/root" replace />, index: true },
            {
              path: 'root',
              element: (
                <RoleBasedGuard permission="view_wallettransaction">
                  <Transaction />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_wallettransaction">
                  <TransactionCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'borlette',
          children: [
            { element: <Navigate to="/dashboard/borlette/list-fiches" replace />, index: true },
            {
              path: 'blocage-boule',
              element: (
                <RoleBasedGuard permission="view_bouleblock">
                  <Boule />
                </RoleBasedGuard>
              )
            },
            {
              path: 'blocage-boule/new',
              element: (
                <RoleBasedGuard permission="add_bouleblock">
                  <BouleCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'statistiques',
              element: (
                <RoleBasedGuard permission={PERMISSIONS.VIEW_STATISTIQUE}>
                  <Statistique />
                </RoleBasedGuard>
              )
            },
            {
              path: 'lotto',
              element: (
                <RoleBasedGuard permission="view_lotto">
                  <Lotto />
                </RoleBasedGuard>
              )
            },
            {
              path: 'lotto/new',
              element: (
                <RoleBasedGuard permission="add_lotto">
                  <LottoCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'lotto/:optionId/edit',
              element: (
                <RoleBasedGuard permission="change_lotto">
                  <LottoCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list-fiches',
              element: (
                <RoleBasedGuard permission="view_fichevente">
                  <Fiche />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list-fiches-gagnants',
              element: (
                <RoleBasedGuard permission={PERMISSIONS.VIEW_FICHE_WIN}>
                  <FicheGagnant />
                </RoleBasedGuard>
              )
            },
            {
              path: 'list-fiches-supprimer',
              element: (
                <RoleBasedGuard permission={PERMISSIONS.VIEW_FICHE_DELETE}>
                  <FicheSupp />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'borlette-configuration',
          children: [
            { element: <Navigate to="/dashboard/borlette-configuration/limite-game" replace />, index: true },
            {
              path: 'limite-game',
              element: (
                <RoleBasedGuard permission="view_typegame">
                  <TypeGame />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-game/new',
              element: (
                <RoleBasedGuard permission="add_typegame">
                  <TypeGameCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-game/:typeGameId/edit',
              element: (
                <RoleBasedGuard permission="change_typegame">
                  <TypeGameCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-game-per-agent',
              element: (
                <RoleBasedGuard permission="view_typegameagent">
                  <TypeGameAgent />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-game-per-agent/new',
              element: (
                <RoleBasedGuard permission="add_typegameagent">
                  <TypeGameAgentCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-game-per-agent/:typeGameAgentId/edit',
              element: (
                <RoleBasedGuard permission="change_typegameagent">
                  <TypeGameAgentCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-boule',
              element: (
                <RoleBasedGuard permission="view_typeboule">
                  <TypeBoule />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-boule/new',
              element: (
                <RoleBasedGuard permission="add_typeboule">
                  <TypeBouleCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-boule/:typeBouleId/edit',
              element: (
                <RoleBasedGuard permission="change_typeboule">
                  <TypeBouleCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-boule-per-agent',
              element: (
                <RoleBasedGuard permission="view_typebouleagent">
                  <TypeBouleAgent />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-boule-per-agent/new',
              element: (
                <RoleBasedGuard permission="add_typebouleagent">
                  <TypeBouleAgentCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'limite-boule-per-agent/:typeBouleAgentId/edit',
              element: (
                <RoleBasedGuard permission="change_typebouleagent">
                  <TypeBouleAgentCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'tirage',
          children: [
            { element: <Navigate to="/dashboard/tirage/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard permission="view_tirage">
                  <Tirage />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_tirage">
                  <TirageCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'automatique',
              element: (
                <RoleBasedGuard permission="add_tirage_auto">
                  <TirageAuto />
                </RoleBasedGuard>
              )
            },
            {
              path: ':tirageId/edit',
              element: (
                <RoleBasedGuard permission="change_tirage">
                  <TirageCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'configuration/generale',
              element: (
                <RoleBasedGuard permission="view_tauxtirage">
                  <TirageConfCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'configuration/generale/list',
              element: (
                <RoleBasedGuard permission="view_tauxtirage">
                  <PrimeGenerale />
                </RoleBasedGuard>
              )
            },
            {
              path: 'configuration/:type',
              element: (
                <RoleBasedGuard permission={'view_agenttauxtirage' || 'view_tiragetauxtirage'}>
                  <Prime />
                </RoleBasedGuard>
              )
            },
            {
              path: 'configuration/:type/:configurationId/edit',
              element: (
                <RoleBasedGuard permission={'change_agenttauxtirage' || 'change_tiragetauxtirage'}>
                  <TirageConfCreateModif />
                </RoleBasedGuard>
              )
            },
            {
              path: 'configuration/:type/new',
              element: (
                <RoleBasedGuard permission={'add_agenttauxtirage' || 'add_tiragetauxtirage'}>
                  <TirageConfCreateModif />
                </RoleBasedGuard>
              )
            },
            {
              path: 'infotirage',
              element: (
                <RoleBasedGuard permission="view_infotirage">
                  <InfoTirage />
                </RoleBasedGuard>
              )
            },
            {
              path: 'infotirage/new',
              element: (
                <RoleBasedGuard permission="add_infotirage">
                  <InfoTirageCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'infotirage/:infoTirageId/edit',
              element: (
                <RoleBasedGuard permission="change_infotirage">
                  <InfoTirageCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            {
              path: 'account',
              element: <UserAccount />
            },
            {
              path: 'tracabilite-agent',
              element: (
                <RoleBasedGuard permission="view_traceagentaction">
                  <TracabiliteAgent />
                </RoleBasedGuard>
              )
            },
            {
              path: 'tracabilite-company',
              element: (
                <RoleBasedGuard permission="view_tracecompanyaction">
                  <TracabiliteCompany />
                </RoleBasedGuard>
              )
            },
            {
              path: 'tracabilite-supervisor',
              element: (
                <RoleBasedGuard permission="view_tracesupervisoraction">
                  <TracabiliteSupervisor />
                </RoleBasedGuard>
              )
            },
            {
              path: 'message-push',
              element: (
                <RoleBasedGuard permission="view_apppromote">
                  <AppPromote />
                </RoleBasedGuard>
              )
            },
            {
              path: 'message-push/new',
              element: (
                <RoleBasedGuard permission="add_apppromote">
                  <AppPromoteCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'message-push/:messageId/edit',
              element: (
                <RoleBasedGuard permission="change_apppromote">
                  <AppPromoteCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'pays',
              element: (
                <RoleBasedGuard permission="view_country">
                  <Pays />
                </RoleBasedGuard>
              )
            },
            {
              path: 'pays/new',
              element: (
                <RoleBasedGuard permission="add_country">
                  <PaysCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'pays/:paysId/edit',
              element: (
                <RoleBasedGuard permission="change_country">
                  <PaysCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'agent',
          children: [
            { element: <Navigate to="/dashboard/agent/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard permission="view_agent">
                  <Agent />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_agent">
                  <AgentCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':agentId/edit',
              element: (
                <RoleBasedGuard permission="change_agent">
                  <AgentCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'supervisor',
          children: [
            { element: <Navigate to="/dashboard/supervisor/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard permission="view_supervisor">
                  <Supervisor />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_supervisor">
                  <SupervisorCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':supervisorId/edit',
              element: (
                <RoleBasedGuard permission="change_supervisor">
                  <SupervisorCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'company',
          children: [
            { element: <Navigate to="/dashboard/company/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard permission="view_company">
                  <Compagnies />
                </RoleBasedGuard>
              )
            },
            {
              path: 'facturation',
              element: <Facturation />
            },
            {
              path: 'facturation/:factuId/pdf',
              element: <FacturationPdf />
            },
            {
              path: 'payment',
              element: (
                <RoleBasedGuard permission="view_payment">
                  <CompanyPayment />
                </RoleBasedGuard>
              )
            },
            {
              path: 'payment/new',
              element: (
                <RoleBasedGuard permission="add_payment">
                  <CompanyPaymentCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'payment/:paymentId/edit',
              element: (
                <RoleBasedGuard permission="change_payment">
                  <CompanyPaymentCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_company">
                  <CompagnieCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':companyId/edit',
              element: (
                <RoleBasedGuard permission="change_company">
                  <CompagnieCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'generale',
          children: [
            { element: <Navigate to="/dashboard/generale/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard permission="view_generale">
                  <Generale />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_generale">
                  <GeneraleCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':generaleId/edit',
              element: (
                <RoleBasedGuard permission="change_generale">
                  <GeneraleCreate />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'surcussale',
          children: [
            { element: <Navigate to="/dashboard/surcussale/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard permission="view_surcussale">
                  <Surcussale />
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard permission="add_surcussale">
                  <SurcussaleCreate />
                </RoleBasedGuard>
              )
            },
            {
              path: ':surcussaleId/edit',
              element: (
                <RoleBasedGuard permission="change_surcussale">
                  <SurcussaleCreate />
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [{ path: '*', element: <Navigate to="/404" replace /> }]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
          index: true
        },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: 'fiche-view/:refCode',
      element: <FicheDetail />
    }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const Agent = Loadable(lazy(() => import('../pages/dashboard/Agents')));
const AgentCreate = Loadable(lazy(() => import('../pages/dashboard/AgentCreate')));
const Supervisor = Loadable(lazy(() => import('../pages/dashboard/Supervisors')));
const Compagnies = Loadable(lazy(() => import('../pages/dashboard/Compagnies')));
const CompagnieCreate = Loadable(lazy(() => import('../pages/dashboard/CompagnieCreate')));
const CompanyPayment = Loadable(lazy(() => import('../pages/dashboard/CompanyPayment')));
const CompanyPaymentCreate = Loadable(lazy(() => import('../pages/dashboard/CompanyPaymentCreate')));
const Generale = Loadable(lazy(() => import('../pages/dashboard/Generale')));
const GeneraleCreate = Loadable(lazy(() => import('../pages/dashboard/GeneraleCreate')));
const AppPromote = Loadable(lazy(() => import('../pages/dashboard/AppPromote')));
const AppPromoteCreate = Loadable(lazy(() => import('../pages/dashboard/AppPromoteCreate')));
const Pays = Loadable(lazy(() => import('../pages/dashboard/Pays')));
const PaysCreate = Loadable(lazy(() => import('../pages/dashboard/PaysCreate')));
const SupervisorCreate = Loadable(lazy(() => import('../pages/dashboard/SupervisorCreate')));
const Lotto = Loadable(lazy(() => import('../pages/dashboard/Lottos')));
const LottoCreate = Loadable(lazy(() => import('../pages/dashboard/LottoCreate')));
const Surcussale = Loadable(lazy(() => import('../pages/dashboard/Surcussales')));
const SurcussaleCreate = Loadable(lazy(() => import('../pages/dashboard/SurcussaleCreate')));
const Tirage = Loadable(lazy(() => import('../pages/dashboard/Tirages')));
const TirageCreate = Loadable(lazy(() => import('../pages/dashboard/TirageCreate')));
const TirageAuto = Loadable(lazy(() => import('../pages/dashboard/TirageAuto')));
const Rapport = Loadable(lazy(() => import('../pages/dashboard/Rapports/RapportInit')));
const RapportJournalier = Loadable(lazy(() => import('../pages/dashboard/RapportJournalier')));
const RapportAgent = Loadable(lazy(() => import('../pages/dashboard/RapportAgents/RapportInit')));
const Facturation = Loadable(lazy(() => import('../pages/dashboard/Facturation')));
const FacturationPdf = Loadable(lazy(() => import('../pages/dashboard/FacturationPdf')));
const Boule = Loadable(lazy(() => import('../pages/dashboard/Boules')));
const BouleCreate = Loadable(lazy(() => import('../pages/dashboard/BouleCreate')));
const InfoTirage = Loadable(lazy(() => import('../pages/dashboard/InfoTirages')));
const InfoTirageCreate = Loadable(lazy(() => import('../pages/dashboard/InfoTirageCreate')));
const Transaction = Loadable(lazy(() => import('../pages/dashboard/Transaction')));
const TransactionCreate = Loadable(lazy(() => import('../pages/dashboard/TransactionCreate')));
const TypeGame = Loadable(lazy(() => import('../pages/dashboard/TypeGame')));
const TypeGameCreate = Loadable(lazy(() => import('../pages/dashboard/TypeGameCreate')));
const TypeBoule = Loadable(lazy(() => import('../pages/dashboard/TypeBoule')));
const TypeBouleCreate = Loadable(lazy(() => import('../pages/dashboard/TypeBouleCreate')));
const TypeGameAgent = Loadable(lazy(() => import('../pages/dashboard/TypeGameAgent')));
const TypeGameAgentCreate = Loadable(lazy(() => import('../pages/dashboard/TypeGameAgentCreate')));
const TypeBouleAgent = Loadable(lazy(() => import('../pages/dashboard/TypeBouleAgent')));
const TypeBouleAgentCreate = Loadable(lazy(() => import('../pages/dashboard/TypeBouleAgentCreate')));
const Prime = Loadable(lazy(() => import('../pages/dashboard/Prime')));
const TirageConfCreate = Loadable(lazy(() => import('../pages/dashboard/TirageConfCreate')));
const PrimeGenerale = Loadable(lazy(() => import('../pages/dashboard/PrimeGenerale')));
const TirageConfCreateModif = Loadable(lazy(() => import('../pages/dashboard/TirageConfCreateModif')));
const Statistique = Loadable(lazy(() => import('../pages/dashboard/Statistiques')));
const TracabiliteAgent = Loadable(lazy(() => import('../pages/dashboard/TracabiliteAgents')));
const TracabiliteSupervisor = Loadable(lazy(() => import('../pages/dashboard/TracabiliteSupervisors')));
const TracabiliteCompany = Loadable(lazy(() => import('../pages/dashboard/TracabiliteCompany')));
const Fiche = Loadable(lazy(() => import('../pages/dashboard/Fiches')));
const FicheDetail = Loadable(lazy(() => import('../pages/dashboard/FicheDetail')));
const FicheGagnant = Loadable(lazy(() => import('../pages/dashboard/FicheGagnants')));
const FicheSupp = Loadable(lazy(() => import('../pages/dashboard/FicheSupp')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
